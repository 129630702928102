<template>
  <el-container style="height:calc(100vh);">
    <el-header>
      <span class="left el-icon-arrow-left" @click="returnOn">优惠设置</span>
      商品改价设置
      <div class="right" @click="returnOn">保存</div>
    </el-header>
    <el-main class="main">
      <div class="content">
        <div class="cell-t">
          <div class="title">
            商品改价功能
          </div>
          <div class="value">
            <el-switch v-model="switchValue" active-color="#409eff">
            </el-switch>
          </div>
        </div>

        <div class="box">
          <div class="box-title">
            设置上下限
          </div>
          <div class="box-item" @click="priceType=1">
            <div class="left">按金额</div>
            <div :class="priceType===1?'right el-icon-check':'el-icon-check'"></div>
          </div>
          <div class="box-item" @click="priceType=2">
            <div class="left">按比例</div>
            <div :class="priceType===2?'right el-icon-check':'el-icon-check'"></div>
          </div>
        </div>

        <div class="box" v-if="priceType===1">
          <div class="box-item">
            <div class="left">根据商品金额最多可加</div>
            <div class="r-ipt">
              <el-input v-model="priceInput1" placeholder="输入金额"></el-input>元
            </div>
          </div>
          <div class="box-item">
            <div class="left">根据商品金额最多可减</div>
            <div class="r-ipt">
              <el-input v-model="priceInput2" placeholder="输入金额"></el-input>元
            </div>
          </div>
        </div>
        <div class="box" v-if="priceType===2">
          <div class="box-item">
            <div class="left">根据商品金额最多可加</div>
            <div class="r-ipt">
              <el-input v-model="discountInput1" placeholder="输入比例"></el-input>%
            </div>
          </div>
          <div class="box-item">
            <div class="left">根据商品金额最多可减</div>
            <div class="r-ipt">
              <el-input v-model="discountInput2" placeholder="输入比例"></el-input>%
            </div>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        switchValue: true,
        priceType: 1,
        priceInput1: '',
        priceInput2: '',
        discountInput1: '',
        discountInput2: '',

      }
    },
    created() {},
    methods: {
      // 返回上级
      returnOn() {
        this.$router.push({
          name: 'PreferentialSet'
        })
      },

    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #666;

      &::before {
        color: #46a6ff;
        font-size: 18px;
        line-height: 18px;
      }
    }

    .right {
      color: #46a6ff;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .main {
    background-color: #f9f9f9;
  }

  .content {
    padding: 20px;

    .cell-t {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0 2px #ccc;
      padding: 10px 20px;
      background-color: #fff;
      margin-bottom: 15px;

      .title {
        line-height: 24px;
      }

    }

    .box {
      padding: 10px;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      color: #333;
      margin-bottom: 20px;

      .box-title {
        line-height: 40px;
        padding: 0 10px;
        border-bottom: 1px solid #f2f2f2;
      }

      .box-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        border-bottom: 1px solid #f2f2f2;
        padding: 0 10px;

        &:last-child {
          line-height: 40px;
          border-bottom: none;
        }

        .el-icon-check {
          font-weight: bold;
          color: #46a6ff;
          display: none;
        }

        .right {
          display: block;
        }

        .r-ipt {
          display: flex;

          /deep/ .el-input {
            .el-input__inner {
              text-align: right;
              border: none;
            }
          }
        }
      }
    }

  }

</style>
